<template>
  <div class="my-trade-item">
    <div class="my-trades-cmn__row">
      <cell-car
        :item="item"
        :template="template"
      />

      <div class="cells-cmn__cell">
        <span class="cells-cmn__str cells-cmn__str_sec">
          <ui-icon icon="pin-alt" />
          <span>{{ item.location }}</span>
        </span>
      </div>

      <div class="cells-cmn__cell my-trade-item__cell">
        <span
          class="my-trade-item__cell-str cells-cmn__str cells-cmn__str_sec"
          :class="{ 'my-trade-item__cell-str_auction': item.isAuction }"
        >
          <template v-if="item.isAuction">
            {{ $t('AUCTION_LOT_TYPE') }}
          </template>

          <template v-else>
            {{ $t('DIRECT_SALE_LOT_TYPE') }}
          </template>
        </span>
      </div>

      <div class="cells-cmn__cell">
        <span class="cells-cmn__str cells-cmn__str_sec">
          {{ $fc(item.price, item.currency) }}
        </span>
      </div>

      <cell-actions
        :item="item"
        :template="template"
        @toggle-details="isDetailsShown = !isDetailsShown"
      />
    </div>

    <lot-details
      v-if="isDetailsShown"
      class="my-trade-item__lot"
      :lot="item"
    />
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import CellCar from './CellCar'
import CellActions from './CellActions'
import LotDetails from './LotDetails'

import { MY_TRADES_LIST_TEMPLATES } from '../constants'

import { LotFrontOffice } from 'Models/LotFrontOffice'
import { HorizonLot } from 'Models/HorizonLot'

export default {
  name: 'my-trade-item',
  components: {
    UiIcon,
    CellCar,
    CellActions,
    LotDetails,
  },

  props: {
    item: { type: [LotFrontOffice, HorizonLot], required: true },
    template: {
      type: String,
      default: MY_TRADES_LIST_TEMPLATES.active,
      validator (value) {
        return Object.values(MY_TRADES_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      isDetailsShown: false,
    }
  },

  computed: {
    MY_TRADES_LIST_TEMPLATES: () => MY_TRADES_LIST_TEMPLATES
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";
@import "../styles/my-trades.scss";

.my-trade-item {
  padding: 2em 0;
  border-bottom: 1px solid rgba($color-grey, 0.5);

  &__cell {
    &-str {
      color: $color-lot-sale;

      &_auction {
        color: $color-lot-auction;
      }
    }
  }

  &__lot {
    border-top: 1px solid rgba($color-grey, 0.5);
    margin-top: 2em;
    padding-top: 2em;
  }
}
</style>

<i18n>
{
  "en": {
    "DIRECT_SALE_LOT_TYPE": "Direct Sale",
    "AUCTION_LOT_TYPE": "Auction"
  },
  "ka": {
    "DIRECT_SALE_LOT_TYPE": "პირდაპირი გაყიდვა",
    "AUCTION_LOT_TYPE": "აუქციონი"
  },
  "ru": {
    "DIRECT_SALE_LOT_TYPE": "Прямая продажа",
    "AUCTION_LOT_TYPE": "Аукцион"
  },
  "uk": {
    "DIRECT_SALE_LOT_TYPE": "Прямий продаж",
    "AUCTION_LOT_TYPE": "Аукціон"
  }
}
</i18n>
