<template>
  <div class="my-trades">
    <page-subnav class="my-trades__subnav">
      <subnav-search
        v-if="template !== MY_TRADES_LIST_TEMPLATES.approval"
        class="my-trades__subnav-action"
        :value="$route.query.search"
        @input="onSearch"
      />

      <template slot="right">
        <router-link
          class="my-trades__tab"
          active-class="my-trades__tab_active"
          :to="{ params: { tab: MY_TRADES_URL_PARAM_TABS.active } }"
        >
          {{ $t('ACTIVE_TAB') }}
        </router-link>

        <router-link
          class="my-trades__tab"
          active-class="my-trades__tab_active"
          :to="{ params: { tab: MY_TRADES_URL_PARAM_TABS.approval } }"
        >
          {{ $t('APPROVAL_TAB') }}
        </router-link>

        <router-link
          class="my-trades__tab"
          active-class="my-trades__tab_active"
          :to="{ params: { tab: MY_TRADES_URL_PARAM_TABS.archive } }"
        >
          {{ $t('ARCHIVE_TAB') }}
        </router-link>
      </template>
    </page-subnav>

    <my-trades-list
      class="my-trades__list"
      :template="template"
      @update-list-ask="loadList"
    />
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import SubnavSearch from 'Common/SubnavSearch'
import MyTradesList from './components/MyTradesList'

import { MY_TRADES_LIST_TEMPLATES } from './constants'
import { MY_TRADES_URL_PARAM_TABS } from 'Constants/myTradesUrlParams'

import { mapActions } from 'vuex'
import { myTradesActions } from './store/types'

import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import { showError } from 'Utils/notifications'

const TAB_TO_TEMPLATE_MAP = Object.freeze({
  [MY_TRADES_URL_PARAM_TABS.active]: MY_TRADES_LIST_TEMPLATES.active,
  [MY_TRADES_URL_PARAM_TABS.approval]: MY_TRADES_LIST_TEMPLATES.approval,
  [MY_TRADES_URL_PARAM_TABS.archive]: MY_TRADES_LIST_TEMPLATES.archive,
})

const TAB_TO_LOAD_ACTION_MAP = Object.freeze({
  [MY_TRADES_URL_PARAM_TABS.active]:
    myTradesActions.LOAD_ACTIVE_LIST,
  [MY_TRADES_URL_PARAM_TABS.approval]:
    myTradesActions.LOAD_APPROVAL_LIST,
  [MY_TRADES_URL_PARAM_TABS.archive]:
    myTradesActions.LOAD_ARCHIVE_LIST,
})

export default {
  name: 'my-trades',
  components: {
    PageSubnav,
    SubnavSearch,
    MyTradesList,
  },

  data () {
    return {
      template: TAB_TO_TEMPLATE_MAP[MY_TRADES_URL_PARAM_TABS.active],
    }
  },

  computed: {
    metaTitle () {
      const tabTitles = {
        [MY_TRADES_URL_PARAM_TABS.active]: this.$t('META_TITLE_ACTIVE'),
        [MY_TRADES_URL_PARAM_TABS.approval]: this.$t('META_TITLE_APPROVAL'),
        [MY_TRADES_URL_PARAM_TABS.archive]: this.$t('META_TITLE_ARCHIVE'),
      }
      return tabTitles[this.$route.params.tab]
    },

    listPayload () {
      return {
        search: this.$route.query.search || undefined,
      }
    },

    MY_TRADES_URL_PARAM_TABS: () => MY_TRADES_URL_PARAM_TABS,
    MY_TRADES_LIST_TEMPLATES: () => MY_TRADES_LIST_TEMPLATES,
  },

  watch: {
    '$route.params.tab': {
      immediate: true,
      handler (tab, oldTab) {
        if (isEqual(tab, oldTab)) return
        this.loadList()
      }
    }
  },

  methods: {
    ...mapActions('ui/my-trades', {
      loadListAction: myTradesActions.LOAD_LIST,
      setIsLoading: myTradesActions.SET_IS_LOADING,
    }),

    onSearch (value) {
      const querySearch = this.$route.query.search || ''
      const search = value.trim()
      if (search === querySearch) return

      this.setIsLoading(true)
      this.applySearchDebounced(search)
    },

    async loadList () {
      try {
        const tab = this.$route.params.tab
        await this.loadListAction({
          action: TAB_TO_LOAD_ACTION_MAP[tab],
          opts: this.listPayload
        })

        this.template = TAB_TO_TEMPLATE_MAP[tab]
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },

    applySearchDebounced: debounce(async function (value) {
      this.$router.push({
        query: { search: value || undefined }
      })
      this.loadList()
    }, 500),
  },

  metaInfo () {
    return {
      title: this.metaTitle,
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style scoped lang="scss">
.my-trades {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 1.4em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "ACTIVE_TAB": "ACTIVE",
    "APPROVAL_TAB": "WAITING FOR APPROVAL",
    "ARCHIVE_TAB": "ARCHIVE",
    "META_TITLE_ACTIVE": "Active",
    "META_TITLE_APPROVAL": "Waiting for approval",
    "META_TITLE_ARCHIVE": "Archive",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the my trades list, please try again later or contact the system owner."
  },
  "ka": {
    "ACTIVE_TAB": "ᲐᲥᲢᲘᲣᲠᲘ",
    "APPROVAL_TAB": "ᲓᲐᲡᲐᲓᲐᲡᲢᲣᲠᲔᲑᲔᲚᲘ",
    "ARCHIVE_TAB": "ᲐᲠᲥᲘᲕᲘ",
    "META_TITLE_ACTIVE": "აქტიური",
    "META_TITLE_APPROVAL": "დასადასტურებელი",
    "META_TITLE_ARCHIVE": "არქივი",
    "LIST_FETCH_FAILED_NOTIFY": "სიის ჩატვირთვა ვერ ხდება, სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "ACTIVE_TAB": "АКТИВНЫЕ",
    "APPROVAL_TAB": "ОЖИДАЮТ ПОДТВЕРЖДЕНИЯ",
    "ARCHIVE_TAB": "АРХИВ",
    "META_TITLE_ACTIVE": "Активные",
    "META_TITLE_APPROVAL": "На подтверждении",
    "META_TITLE_ARCHIVE": "Архив",
    "LIST_FETCH_FAILED_NOTIFY": "Не удается загрузить список моих сделок. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "ACTIVE_TAB": "АКТИВНІ",
    "APPROVAL_TAB": "ОЧІКУЮТЬ НА СХВАЛЕННЯ",
    "ARCHIVE_TAB": "АРХІВ",
    "META_TITLE_PLAYING": "Активні",
    "META_TITLE_APPROVAL": "Очікують схвалення",
    "META_TITLE_ARCHIVE": "Архів",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдається завантажити список моїх угод. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
