<template>
  <list-grouped
    class="my-trades-list"
    :list="list"
    :list-total-count="0"
    group-by-date-field="createdAt"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="MY_TRADES_PAGE_LIMIT"
    :load-more="loadMore"
    :reset-on-change="template"
    :head-component="MyTradesListHead"
    :head-component-props="{ template }"
    :item-component="MyTradeItem"
    :item-component-props="{ template }"
    :item-skeleton-component="MyTradeItemSkeleton"
    @update-list-ask="$emit('update-list-ask')"
  />
</template>

<script>
import ListGrouped from 'Common/ListGrouped'
import MyTradeItem from './MyTradeItem'
import MyTradeItemSkeleton from './MyTradeItemSkeleton'
import MyTradesListHead from './MyTradesListHead'

import { mapActions, mapGetters } from 'vuex'
import { myTradesActions, myTradesGetters } from '../store/types'

import {
  MY_TRADES_PAGE_LIMIT,
  MY_TRADES_LIST_TEMPLATES
} from '../constants'

export default {
  name: 'my-trades-list',
  components: {
    ListGrouped
  },

  props: {
    template: {
      type: String,
      default: MY_TRADES_LIST_TEMPLATES.active,
      validator (value) {
        return Object.values(MY_TRADES_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      MyTradeItem,
      MyTradeItemSkeleton,
      MyTradesListHead,

      MY_TRADES_PAGE_LIMIT,
      MY_TRADES_LIST_TEMPLATES,
    }
  },

  computed: {
    ...mapGetters('ui/my-trades', {
      isLoading: myTradesGetters.IS_LOADING,
      isNextLoading: myTradesGetters.IS_NEXT_LOADING,
      list: myTradesGetters.LIST,
    }),
  },

  methods: {
    ...mapActions('ui/my-trades', {
      loadMore: myTradesActions.LOAD_MORE,
    }),
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/my-trades.scss";

.my-trades-list {
  --list-padding-side: 3em;
}
</style>
