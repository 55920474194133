<template>
  <div class="lot-gallery">
    <button
      v-for="(image, index) in previewImages"
      :key="index"
      class="lot-gallery__btn"
      @click="showGallery(index)"
    >
      <ui-img
        class="lot-gallery__thumbnail"
        v-bind="image"
      />
    </button>

    <div
      v-if="lot.images.length > PREVIEW_IMAGES_COUNT + 1"
      class="lot-gallery__more"
      @click="showGallery(PREVIEW_IMAGES_COUNT)"
    >
      <span>{{ $t('COMMON.SYMBOLS.PLUS') }}</span>
      <span>{{ lot.images.length - PREVIEW_IMAGES_COUNT }}</span>
    </div>

    <ui-fullscreen-gallery
      :open.sync="isGalleryOpen"
      :active-slide="galleryIndex"
      :slides="fullscreenImages"
    />
  </div>
</template>

<script>
import { UiImg, UiFullscreenGallery } from '@shelf.network/ui-kit'

import { LotFrontOffice } from 'Models/LotFrontOffice'
import { HorizonLot } from 'Models/HorizonLot'

import { generateImageProps, lazySmallImg } from 'Utils/generateImageProps'

const PREVIEW_IMAGES_COUNT = 5

export default {
  name: 'lot-gallery',
  components: {
    UiImg,
    UiFullscreenGallery,
  },

  props: {
    lot: { type: [LotFrontOffice, HorizonLot], required: true },
  },

  data () {
    return {
      isGalleryOpen: false,
      galleryIndex: 0,
    }
  },

  computed: {
    images () {
      return this.lot.hdImages.length >= this.lot.images.length
        ? this.lot.hdImages
        : this.lot.images
    },

    youtubeThumbnailUrl () {
      if (!this.lot.youtubeLink) return ''

      const youtubeIdRegexp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#]*).*/
      const match = this.lot.youtubeLink.match(youtubeIdRegexp)

      return `https://img.youtube.com/vi/${match ? match[2] : this.lot.youtubeLink}/hqdefault.jpg`
    },

    previewImages () {
      return [this.youtubeThumbnailUrl, ...this.images]
        .filter(item => Boolean(item))
        .slice(0, PREVIEW_IMAGES_COUNT)
        .map(src => lazySmallImg(src, this.lot.name))
    },

    fullscreenImages () {
      const images = this.images.map(image => {
        return generateImageProps({
          src: image,
          srcSetOptions: [],
          sizes: '80vw',
          placeholderOptions: { mode: 'fit', width: 64, height: 48 }
        })
      })

      return this.lot.youtubeLink
        ? [{ src: this.lot.youtubeLink }, ...images]
        : images
    },

    PREVIEW_IMAGES_COUNT: () => PREVIEW_IMAGES_COUNT,
  },

  methods: {
    showGallery (index) {
      this.galleryIndex = index
      this.isGalleryOpen = true
    }
  }
}
</script>

<style lang="scss" scoped>
.lot-gallery {
  display: grid;
  width: 260px;
  grid-template-columns: 1fr 1fr;
  gap: 0.6em;

  &__btn {
    background-color: transparent;
    border: none;
    outline: none;
  }

  &__thumbnail,
  &__more {
    @include aspect-ratio();

    --ratio: calc(4 / 3);

    position: relative;
    cursor: pointer;
    border-radius: 8px;
    transition: all 200ms ease-out;
  }

  &__thumbnail:hover {
    filter: brightness(0.8);
  }

  &__more {
    background-color: rgba($color-black, 0.6);
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.4em;

    &:hover {
      background-color: rgba($color-black, 0.8);
    }
  }
}
</style>
