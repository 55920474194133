<template>
  <div class="cell-car cells-cmn__cell">
    <component
      :is="lotLinkComponent"
      class="cell-car__link cell-car__img-wrp"
      :href="lotLink"
      target="_blank"
      rel="nofollow noopener"
    >
      <ui-img
        class="cell-car__img"
        v-bind="lazyLotImg"
        :title="item.name"
        :alt="item.id"
      />
    </component>

    <div class="cells-cmn__cell-col">
      <component
        :is="lotLinkComponent"
        class="
          cell-car__name
          cells-cmn__str
          cells-cmn__str_nb
        "
        :href="lotLink"
        target="_blank"
        rel="nofollow noopener"
        :title="item.name"
      >
        {{ item.name }}
      </component>

      <span class="cell-car__desc cells-cmn__str cells-cmn__str_sec">
        <span>{{ $t('ID_FORMAT', { id: item.id }) }}</span>

        <ui-copy-button
          :value="item.id"
          :copied-message="$t('COMMON.COPIED_MSG')"
        />
      </span>

      <span
        v-if="item.vinCode"
        class="cell-car__desc cells-cmn__str cells-cmn__str_sec"
      >
        <span>{{ $t('VIN_FORMAT', { vin: item.vinCode }) }}</span>

        <ui-copy-button
          :value="item.vinCode"
          :copied-message="$t('COMMON.COPIED_MSG')"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { UiImg, UiCopyButton } from '@shelf.network/ui-kit'

import { mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'

import { LotFrontOffice } from 'Models/LotFrontOffice'
import { HorizonLot } from 'Models/HorizonLot'
import { MY_TRADES_LIST_TEMPLATES } from '../constants'

import { lazyTinyImg } from 'Utils/generateImageProps'
import placeholderPath from 'Assets/icons/placeholder.svg'

export default {
  name: 'cell-car',
  components: {
    UiImg,
    UiCopyButton,
  },

  props: {
    item: { type: [LotFrontOffice, HorizonLot], required: true },
    template: {
      type: String,
      default: MY_TRADES_LIST_TEMPLATES.active,
      validator (value) {
        return Object.values(MY_TRADES_LIST_TEMPLATES).includes(value)
      },
    },
  },

  computed: {
    ...mapGetters('entities/user', {
      userPlatform: userGetters.PLATFORM,
    }),

    lazyLotImg () {
      return this.item.images.length > 0
        ? lazyTinyImg(this.item.images[0], true)
        : { src: placeholderPath }
    },

    lotLink () {
      return this.template === MY_TRADES_LIST_TEMPLATES.approval
        ? ''
        : `${this.userPlatform.clientUrl}/lot/${this.item.id}`
    },

    lotLinkComponent () {
      return this.template === MY_TRADES_LIST_TEMPLATES.approval ? 'p' : 'a'
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";

.cell-car {
  &__img-wrp {
    margin-right: 0.9em;
  }

  &__img {
    --ui-img-border-radius: 5px;

    display: block;
    height: 5.2em;
    max-width: 6.4em;
    min-width: 6.4em;
  }

  &__name {
    text-decoration: none;
    color: $color-dark;
    font-size: 1.1em;
  }

  &__desc {
    font-size: 0.9em;
  }

  &__location {
    font-size: 0.7em;
    line-height: 1.5;
    font-weight: 500;
  }
}
</style>

<i18n>
{
  "en": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  },
  "ka": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  },
  "ru": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  },
  "uk": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  }
}
</i18n>
