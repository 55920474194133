<template>
  <div class="lot-description">
    <div
      v-if="descriptionLocales.length > 0"
      class="lot-description__locales"
    >
      <button
        v-for="locale in descriptionLocales"
        :key="locale"
        class="lot-description__locale"
        :class="{
          'lot-description__locale_selected': locale === selectedLocale
        }"
        @click="selectedLocale = locale"
      >
        {{ locale.toUpperCase() }}
      </button>
    </div>

    <div class="lot-description__txt">
      <template v-if="selectedDescription">
        <p
          v-for="(paragraph, i) in descriptionParagraphs"
          :key="i"
          class="lot-description__txt-paragraph"
        >
          {{ paragraph }}
        </p>
      </template>

      <p
        v-else
        class="lot-description__txt-stub"
      >
        {{ $t('NO_DESCRIPTION_MSG') }}
      </p>
    </div>
  </div>
</template>

<script>
import { LotFrontOffice } from 'Models/LotFrontOffice'
import { HorizonLot } from 'Models/HorizonLot'

export default {
  name: 'lot-description',

  props: {
    lot: { type: [LotFrontOffice, HorizonLot], required: true },
  },

  data () {
    return {
      selectedLocale: ''
    }
  },

  computed: {
    descriptionLocales () {
      return Object.keys(this.lot.descriptionLocalized)
        .filter(locale => this.lot.descriptionLocalized[locale])
        .sort()
    },

    selectedDescription () {
      return this.selectedLocale
        ? this.lot.descriptionLocalized[this.selectedLocale] || ''
        : this.lot.description
    },

    descriptionParagraphs () {
      return this.selectedDescription.split('\n')
    }
  },

  created () {
    if (this.descriptionLocales.length > 0) {
      this.selectedLocale = this.descriptionLocales[0]
    }
  },
}
</script>

<style lang="scss" scoped>
.lot-description {
  &__locales {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    gap: 1.6em;
  }

  &__locale {
    background-color: transparent;
    border: none;
    color: $color-ui-secondary;
    padding: 0.4em 1em;
    border-bottom: 1px solid rgba($color-grey, 0.5);

    &_selected {
      border-color: $color-black;
      color: $color-black;
    }
  }

  &__txt {
    margin-top: 1.6em;
    display: grid;
    gap: 0.5em;
    line-height: 1.5;
    word-break: break-all;

    &-stub {
      color: $color-ui-secondary;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "NO_DESCRIPTION_MSG": "No description"
  },
  "ka": {
    "NO_DESCRIPTION_MSG": "აღწერა აკლია"
  },
  "ru": {
    "NO_DESCRIPTION_MSG": "Описание отсутствует"
  },
  "uk": {
    "NO_DESCRIPTION_MSG": "Опис відсутній"
  }
}
</i18n>
