<template>
  <div class="lot-spec">
    <div class="lot-spec__row">
      <span class="lot-spec__row-lbl">{{ $t('MAKER_LBL') }}</span>
      <span class="lot-spec__row-val">
        {{ extract(lot.maker, true, 'MAKER') }}
      </span>
    </div>

    <div class="lot-spec__row">
      <span class="lot-spec__row-lbl">{{ $t('MODEL_LBL') }}</span>
      <span class="lot-spec__row-val">
        {{ extract(lot.model, true, 'MODEL') }}
      </span>
    </div>

    <div class="lot-spec__row">
      <span class="lot-spec__row-lbl">{{ $t('REG_YEAR_LBL') }}</span>
      <span class="lot-spec__row-val">{{ extract(lot.year) }}</span>
    </div>

    <div class="lot-spec__row">
      <span class="lot-spec__row-lbl">{{ $t('MILEAGE_LBL') }}</span>
      <span class="lot-spec__row-val">
        <template v-if="lot.odometerValueKm">
          {{ $t('MILEAGE_VAL', { mileage: $n(lot.odometerValueKm) }) }}
        </template>

        <template v-else>
          {{ $t('COMMON.SYMBOLS.MDASH') }}
        </template>
      </span>
    </div>

    <div class="lot-spec__row">
      <span class="lot-spec__row-lbl">
        {{ $t('ENGINE_CAPACITY_LBL') }}
      </span>
      <span class="lot-spec__row-val">
        <template v-if="lot.engineVolume">
          {{
            $t('ENGINE_CAPACITY_VAL', {
              capacity: $n((lot.engineVolume).toFixed(1))
            })
          }}
        </template>

        <template v-else>{{ $t('COMMON.SYMBOLS.MDASH') }}</template>
      </span>
    </div>

    <div class="lot-spec__row">
      <span class="lot-spec__row-lbl">{{ $t('FUEL_TYPE_LBL') }}</span>
      <span class="lot-spec__row-val">
        {{ extract(lot.fuelType, true, 'FUEL_TYPE') }}
      </span>
    </div>

    <div class="lot-spec__row">
      <span class="lot-spec__row-lbl">{{ $t('TRANSMISSION_LBL') }}</span>
      <span class="lot-spec__row-val">
        {{ extract(lot.transmission, true, 'TRANSMISSION_TYPE') }}
      </span>
    </div>

    <div class="lot-spec__row">
      <span class="lot-spec__row-lbl">{{ $t('WHEEL_LBL') }}</span>
      <span class="lot-spec__row-val">
        {{ extract(lot.wheel, true, 'WHEEL_POSITION') }}
      </span>
    </div>

    <div
      v-if="lot.isDirectSale"
      class="lot-spec__row"
    >
      <span class="lot-spec__row-lbl">{{ $t('REGISTERED_LBL') }}</span>
      <span class="lot-spec__row-val">
        <template v-if="lot.isRegistered">
          {{ $t('COMMON.YES_MSG') }}
        </template>

        <template v-else>
          {{ $t('COMMON.NO_MSG') }}
        </template>
      </span>
    </div>

    <div class="lot-spec__row">
      <span class="lot-spec__row-lbl">{{ $t('ON_ROAD_LBL') }}</span>
      <span class="lot-spec__row-val">
        <template v-if="lot.isOnRoad">
          {{ $t('COMMON.YES_MSG') }}
        </template>

        <template v-else>
          {{ $t('COMMON.NO_MSG') }}
        </template>
      </span>
    </div>

    <div class="lot-spec__row">
      <span class="lot-spec__row-lbl">{{ $t('COLOR_LBL') }}</span>
      <span class="lot-spec__row-val">{{ formattedColor }}</span>
    </div>
  </div>
</template>

<script>
import { LotFrontOffice } from 'Models/LotFrontOffice'
import { HorizonLot } from 'Models/HorizonLot'

import { titleCase } from 'Utils/changeCase'

export default {
  name: 'lot-spec',

  props: {
    lot: { type: [LotFrontOffice, HorizonLot], required: true },
  },

  computed: {
    formattedColor () {
      const extractedColor = this.extract(this.lot.color, true)
      return extractedColor.includes('CAR.')
        ? titleCase(this.lot.color)
        : extractedColor
    },
  },

  methods: {
    extract (value, isTranslatable, enumNamespace = '') {
      if (!value) {
        return this.$t('COMMON.SYMBOLS.MDASH')
      }

      if (isTranslatable && typeof value === 'string') {
        return enumNamespace
          ? this.$et(enumNamespace, value)
          : this.$t(`CAR.${value.toUpperCase()}`)
      }

      return value
    },
  },
}
</script>

<style lang="scss" scoped>
.lot-spec {
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 0.4em 0;
    border-bottom: 1px solid rgba($color-grey, 0.5);

    &-lbl {
      color: $color-ui-secondary;
      flex: 1;
    }

    &-val {
      flex: 1;
      color: $color-black;
      text-align: left;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "MAKER_LBL": "Maker",
    "MODEL_LBL": "Model",
    "REG_YEAR_LBL": "Registration year",
    "MILEAGE_LBL": "Mileage",
    "MILEAGE_VAL": "{mileage} km",
    "ENGINE_CAPACITY_LBL": "Engine Capacity",
    "ENGINE_CAPACITY_VAL": "{capacity} dm³",
    "FUEL_TYPE_LBL": "Fuel type",
    "TRANSMISSION_LBL": "Transmission",
    "WHEEL_LBL": "Wheel",
    "COLOR_LBL": "Color",
    "ON_ROAD_LBL": "On the road",
    "REGISTERED_LBL": "Registered"
  },
  "ka": {
    "MAKER_LBL": "მწარმოებელი",
    "MODEL_LBL": "მოდელი",
    "REG_YEAR_LBL": "რეგისტრაციის წელი",
    "MILEAGE_LBL": "გარბენი",
    "MILEAGE_VAL": "{mileage} კმ",
    "ENGINE_CAPACITY_LBL": "ძრავის მოცულობა",
    "ENGINE_CAPACITY_VAL": "{capacity} კბ³",
    "FUEL_TYPE_LBL": "საწვავის ტიპი",
    "TRANSMISSION_LBL": "გადაცემათა კოლოფი",
    "WHEEL_LBL": "საჭე",
    "COLOR_LBL": "ფერი",
    "ON_ROAD_LBL": "გზაში",
    "REGISTERED_LBL": "განბაჟებული"
  },
  "ru": {
    "MAKER_LBL": "Марка",
    "MODEL_LBL": "Модель",
    "REG_YEAR_LBL": "Год регистрации",
    "MILEAGE_LBL": "Пробег",
    "MILEAGE_VAL": "{mileage} км",
    "ENGINE_CAPACITY_LBL": "Объём двигателя",
    "ENGINE_CAPACITY_VAL": "{capacity} л",
    "FUEL_TYPE_LBL": "Тип топлива",
    "TRANSMISSION_LBL": "Коробка передач",
    "WHEEL_LBL": "Расположение руля",
    "COLOR_LBL": "Цвет",
    "ON_ROAD_LBL": "В дороге",
    "REGISTERED_LBL": "Зарегистрировано"
  },
  "uk": {
    "MAKER_LBL": "Марка",
    "MODEL_LBL": "Модель",
    "REG_YEAR_LBL": "Рік першої реєстрації",
    "MILEAGE_LBL": "Пробіг",
    "MILEAGE_VAL": "{mileage} км",
    "ENGINE_CAPACITY_LBL": "Об'єм двигуна",
    "ENGINE_CAPACITY_VAL": "{capacity} л",
    "FUEL_TYPE_LBL": "Тип палива",
    "TRANSMISSION_LBL": "Трансмісія",
    "WHEEL_LBL": "Розташування керма",
    "COLOR_LBL": "Колір",
    "ON_ROAD_LBL": "У дорозі",
    "REGISTERED_LBL": "Зареєстровано"
  }
}
</i18n>
