<template>
  <div class="lot-details">
    <div class="lot-details__block">
      <h4 class="lot-details__block-title">
        {{ $t('IMAGES_TITLE') }}
      </h4>

      <div class="lot-details__block-content">
        <lot-gallery :lot="lot" />
      </div>
    </div>

    <div class="lot-details__block">
      <h4 class="lot-details__block-title">
        {{ $t('SPEC_TITLE') }}
      </h4>

      <div class="lot-details__block-content">
        <lot-spec :lot="lot" />
      </div>
    </div>

    <div class="lot-details__block">
      <h4 class="lot-details__block-title">
        {{ $t('DESCRIPTION_TITLE') }}
      </h4>

      <div class="lot-details__block-content">
        <lot-description :lot="lot" />
      </div>
    </div>
  </div>
</template>

<script>
import LotGallery from './LotGallery'
import LotSpec from './LotSpec'
import LotDescription from './LotDescription'

import { LotFrontOffice } from 'Models/LotFrontOffice'
import { HorizonLot } from 'Models/HorizonLot'

export default {
  name: 'lot-details',
  components: {
    LotGallery,
    LotSpec,
    LotDescription,
  },

  props: {
    lot: { type: [LotFrontOffice, HorizonLot], required: true },
  },
}
</script>

<style lang="scss" scoped>
.lot-details {
  display: grid;
  grid-template-columns: 260px 1fr 1fr;
  gap: 2.4em;

  &__block {
    &-title {
      color: $color-ui-secondary;
    }

    &-content {
      margin-top: 1.2em;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "IMAGES_TITLE": "IMAGES",
    "SPEC_TITLE": "TECHNICAL CHARACTERISTICS",
    "DESCRIPTION_TITLE": "DESCRIPTION"
  },
  "ka": {
    "IMAGES_TITLE": "ᲤᲝᲢᲝᲔᲑᲘ",
    "SPEC_TITLE": "ᲢᲔᲥᲜᲘᲙᲣᲠᲘ ᲛᲐᲮᲐᲡᲘᲐᲗᲔᲑᲚᲔᲑᲘ",
    "DESCRIPTION_TITLE": "ᲐᲦᲬᲔᲠᲘᲚᲝᲑᲐ"
  },
  "ru": {
    "IMAGES_TITLE": "ИЗОБРАЖЕНИЯ",
    "SPEC_TITLE": "ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ",
    "DESCRIPTION_TITLE": "ОПИСАНИЕ"
  },
  "uk": {
    "IMAGES_TITLE": "ЗОБРАЖЕННЯ",
    "SPEC_TITLE": "ТЕХНІЧНІ ХАРАКТЕРИСТИКИ",
    "DESCRIPTION_TITLE": "ОПИС"
  }
}
</i18n>
