<template>
  <div
    class="my-trades-list-head my-trades-cmn__row"
    :class="{
      'my-trades-cmn__row_approval':
        template === MY_TRADES_LIST_TEMPLATES.approval,
      'my-trades-cmn__row_archive':
        template === MY_TRADES_LIST_TEMPLATES.archive,
    }"
  >
    <span class="my-trades-list-head__cell">
      {{ $t('CAR_HEAD_CELL') }}
    </span>

    <span class="my-trades-list-head__cell">
      {{ $t('LOCATION_HEAD_CELL') }}
    </span>

    <span class="my-trades-list-head__cell">
      {{ $t('TYPE_HEAD_CELL') }}
    </span>

    <span class="my-trades-list-head__cell">
      {{ $t('PRICE_HEAD_CELL') }}
    </span>
  </div>
</template>

<script>
import { MY_TRADES_LIST_TEMPLATES } from '../constants'

export default {
  name: 'my-trades-list-head',
  props: {
    template: {
      type: String,
      default: MY_TRADES_LIST_TEMPLATES.active,
      validator (value) {
        return Object.values(MY_TRADES_LIST_TEMPLATES).includes(value)
      },
    },
  },

  computed: {
    MY_TRADES_LIST_TEMPLATES: () => MY_TRADES_LIST_TEMPLATES
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/my-trades.scss";

.my-trades-list-head {
  padding: 2em 3em 0;

  &__cell {
    line-height: 1.4;
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "CAR_HEAD_CELL": "CAR",
    "LOCATION_HEAD_CELL": "LOCATION",
    "TYPE_HEAD_CELL": "TYPE",
    "PRICE_HEAD_CELL": "PRICE"
  },
  "ka": {
    "CAR_HEAD_CELL": "ᲐᲕᲢᲝᲛᲝᲑᲘᲚᲘ",
    "LOCATION_HEAD_CELL": "ᲚᲝᲙᲐᲪᲘᲐ",
    "TYPE_HEAD_CELL": "ᲢᲘᲞᲘ",
    "PRICE_HEAD_CELL": "ᲤᲐᲡᲘ"
  },
  "ru": {
    "CAR_HEAD_CELL": "АВТО",
    "LOCATION_HEAD_CELL": "ЛОКАЦИЯ",
    "TYPE_HEAD_CELL": "ТИП",
    "PRICE_HEAD_CELL": "ЦЕНА"
  },
  "uk": {
    "CAR_HEAD_CELL": "АВТО",
    "LOCATION_HEAD_CELL": "ЛОКАЦІЯ",
    "TYPE_HEAD_CELL": "ТИП",
    "PRICE_HEAD_CELL": "ЦІНА"
  }
}
</i18n>
