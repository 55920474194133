<template>
  <div class="cell-actions cells-cmn__cell">
    <div class="cell-actions__btns-wrp">
      <template v-if="template === MY_TRADES_LIST_TEMPLATES.active">
        <button
          class="cell-actions__btn cell-actions__btn_danger"
          :title="$t('CLOSE_BTN_HINT')"
          :disabled="isProcessing"
          @click="close"
        >
          <ui-icon
            class="cell-actions__btn-icon"
            icon="close"
          />
        </button>
      </template>

      <template v-else-if="template === MY_TRADES_LIST_TEMPLATES.approval">
        <button
          class="cell-actions__btn cell-actions__btn_success"
          :title="$t('APPROVE_BTN_HINT')"
          :disabled="isProcessing"
          @click="approve"
        >
          <ui-icon
            class="cell-actions__btn-icon"
            icon="success"
          />
        </button>

        <button
          class="cell-actions__btn cell-actions__btn_danger"
          :title="$t('REJECT_BTN_HINT')"
          :disabled="isProcessing"
          @click="reject"
        >
          <ui-icon
            class="cell-actions__btn-icon"
            icon="close"
          />
        </button>
      </template>

      <button
        class="cell-actions__btn"
        :title="$t('DETAILS_BTN_HINT')"
        @click="toggleDetails"
      >
        <ui-icon
          class="cell-actions__btn-icon"
          :class="{ 'cell-actions__btn-icon_open': isDetailsShown }"
          icon="arrow-down"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'

import { mapActions } from 'vuex'
import { myTradesActions } from '../store/types'

import { LotFrontOffice } from 'Models/LotFrontOffice'
import { HorizonLot } from 'Models/HorizonLot'

import { MY_TRADES_LIST_TEMPLATES } from '../constants'
import { showError, showSuccess } from 'Utils/notifications'

export default {
  name: 'cell-actions',
  components: { UiIcon },

  props: {
    item: { type: [LotFrontOffice, HorizonLot], required: true },
    template: {
      type: String,
      default: MY_TRADES_LIST_TEMPLATES.active,
      validator (value) {
        return Object.values(MY_TRADES_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      isDetailsShown: false,
      isProcessing: false,
    }
  },

  computed: {
    MY_TRADES_LIST_TEMPLATES: () => MY_TRADES_LIST_TEMPLATES,
  },

  methods: {
    ...mapActions('ui/my-trades', {
      closeLot: myTradesActions.CLOSE_LOT,
      approveRequest: myTradesActions.APPROVE_REQUEST,
      rejectRequest: myTradesActions.REJECT_REQUEST,
    }),

    async close () {
      const lotName = this.item.name
      if (!confirm(this.$t('CLOSE_CONFIRM_MSG', { lotName }))) {
        return
      }

      this.isProcessing = true
      try {
        await this.closeLot(this.item.id)
        showSuccess(this.$t('CLOSE_SUCCESS_NOTIFY'))
      } catch (error) {
        showError(this.$t('CLOSE_FAILED_NOTIFY'))
        console.error(error)
      }
      this.isProcessing = false
    },

    async approve () {
      const lotName = this.item.name
      if (!confirm(this.$t('APPROVE_CONFIRM_MSG', { lotName }))) {
        return
      }

      this.isProcessing = true
      try {
        await this.approveRequest({
          id: this.item.requestId,
          hash: this.item.hash,
        })
        showSuccess(this.$t('APPROVE_SUCCESS_NOTIFY'))
      } catch (error) {
        showError(this.$t('APPROVE_FAILED_NOTIFY'))
        console.error(error)
      }
      this.isProcessing = false
    },

    async reject () {
      const lotName = this.item.name
      if (!confirm(this.$t('REJECT_CONFIRM_MSG', { lotName }))) {
        return
      }

      this.isProcessing = true
      try {
        await this.rejectRequest({
          id: this.item.requestId,
          hash: this.item.hash,
        })
        showSuccess(this.$t('REJECT_SUCCESS_NOTIFY'))
      } catch (error) {
        showError(this.$t('REJECT_FAILED_NOTIFY'))
        console.error(error)
      }
      this.isProcessing = false
    },

    toggleDetails () {
      this.isDetailsShown = !this.isDetailsShown
      this.$emit('toggle-details')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";

.cell-actions {
  margin-left: auto;

  &__btns-wrp {
    position: relative;
    display: grid;
    grid: auto / auto-flow;
    gap: 1em;
  }

  &__btn {
    color: $color-dark-grey;
    background: $color-light-grey;
    padding: 0;
    height: 3.5em;
    width: 3.5em;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms;

    &:disabled {
      cursor: not-allowed;
      color: $color-grey;
      background: $color-light-grey;
    }

    &:hover:not(:disabled) {
      background: mix($color-light-grey, $color-dark, 85);
    }

    &_success {
      color: $color-white;
      background: $color-flag-is-success;

      &:hover:not(:disabled) {
        background: mix($color-flag-is-success, $color-dark, 85);
      }
    }

    &_danger {
      color: $color-white;
      background: $color-flag-is-error;

      &:hover:not(:disabled) {
        background: mix($color-flag-is-error, $color-dark, 85);
      }
    }

    &-icon {
      font-size: 1.5em;

      &_open {
        transform: rotate(180deg);
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "APPROVE_BTN_HINT": "Approve request",
    "APPROVE_CONFIRM_MSG": "Approve request on lot {lotName}? The action is irreversible.",
    "APPROVE_SUCCESS_NOTIFY": "Request has been approved.",
    "APPROVE_FAILED_NOTIFY": "Cannot approve request. Please try again later or contact the system owner.",
    "REJECT_BTN_HINT": "Reject request",
    "REJECT_CONFIRM_MSG": "Reject request on lot {lotName}? The action is irreversible.",
    "REJECT_SUCCESS_NOTIFY": "Request has been rejected.",
    "REJECT_FAILED_NOTIFY": "Cannot reject request. Please try again later or contact the system owner.",
    "CLOSE_BTN_HINT": "Close the lot",
    "CLOSE_CONFIRM_MSG": "Close the {lotName}? The action is irreversible.",
    "CLOSE_SUCCESS_NOTIFY": "Lot has been closed.",
    "CLOSE_FAILED_NOTIFY": "Cannot close the lot. Please try again later or contact the system owner.",
    "DETAILS_BTN_HINT": "Show lot details"
  },
  "ka": {
    "APPROVE_BTN_HINT": "დადასტურება",
    "APPROVE_CONFIRM_MSG": "მოთხოვნის დადასტურება {lotName}? უკან ცვლილება ვეღარ მოხდება.",
    "APPROVE_SUCCESS_NOTIFY": "მოთხოვნა დადასტურდა.",
    "APPROVE_FAILED_NOTIFY": "მოთხოვნა არ დადასტურდა. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს.",
    "REJECT_BTN_HINT": "უარყოფილია",
    "REJECT_CONFIRM_MSG": "მოთხოვნის უარყოფილია {lotName}? უკან ცვლილება ვეღარ მოხდება.",
    "REJECT_SUCCESS_NOTIFY": "მოთხოვნა უარყოფილია.",
    "REJECT_FAILED_NOTIFY": "მოთხოვნა არ უარყოფილია. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს.",
    "CLOSE_BTN_HINT": "ლოტის დახურვა",
    "CLOSE_CONFIRM_MSG": "გსურთ ლოტის {lotName}? დახურვა? უკან ცვლილება ვეღარ მოხდება.",
    "CLOSE_SUCCESS_NOTIFY": "ლოტი დახურულია.",
    "CLOSE_FAILED_NOTIFY": "ლოტის დახურვა ვერ მოხდა. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს.",
    "DETAILS_BTN_HINT": "ლოტის დეტალები"
  },
  "ru": {
    "APPROVE_BTN_HINT": "Одобрить запрос",
    "APPROVE_CONFIRM_MSG": "Одобрить запрос на лот {lotName}? Действие необратимо.",
    "APPROVE_SUCCESS_NOTIFY": "Запрос одобрен.",
    "APPROVE_FAILED_NOTIFY": "Не удалось одобрить запрос. Повторите попытку позже или свяжитесь с владельцем системы.",
    "REJECT_BTN_HINT": "Отклонить запрос",
    "REJECT_CONFIRM_MSG": "Отклонить запрос на лот {lotName}? Действие необратимо.",
    "REJECT_SUCCESS_NOTIFY": "Запрос отклонён.",
    "REJECT_FAILED_NOTIFY": "Не удалось отклонить запрос. Повторите попытку позже или свяжитесь с владельцем системы.",
    "CLOSE_BTN_HINT": "Закрыть лот",
    "CLOSE_CONFIRM_MSG": "Закрыть лот {lotName}? Действие необратимо.",
    "CLOSE_SUCCESS_NOTIFY": "Лот закрыт.",
    "CLOSE_FAILED_NOTIFY": "Не удалось закрыть лот. Повторите попытку позже или свяжитесь с владельцем системы.",
    "DETAILS_BTN_HINT": "Показать информацию о лоте"
  },
  "uk": {
    "APPROVE_BTN_HINT": "Схвалити запит",
    "APPROVE_CONFIRM_MSG": "Схвалити запит на лот {lotName}? Дія незворотна.",
    "APPROVE_SUCCESS_NOTIFY": "Запит схвалено.",
    "APPROVE_FAILED_NOTIFY": "Не вдалося схвалити запит. Повторіть спробу пізніше або зв’яжіться з власником системи.",
    "REJECT_BTN_HINT": "Відхилити запит",
    "REJECT_CONFIRM_MSG": "Відхилити запит на лот {lotName}? Дія незворотна.",
    "REJECT_SUCCESS_NOTIFY": "Запит відхилено.",
    "REJECT_FAILED_NOTIFY": "Не вдалося відхилити запит. Повторіть спробу пізніше або зв’яжіться з власником системи.",
    "CLOSE_BTN_HINT": "Закрити лот",
    "CLOSE_CONFIRM_MSG": "Закрити лот {lotName}? Дія незворотна.",
    "CLOSE_SUCCESS_NOTIFY": "Лот закрито.",
    "CLOSE_FAILED_NOTIFY": "Не вдалося закрити лот. Повторіть спробу пізніше або зв’яжіться з власником системи.",
    "DETAILS_BTN_HINT": "Показати інформацію про лот"
  }
}
</i18n>
